import { For, Show, createSignal, onMount } from 'solid-js';
import {
  CHINESE,
  CHINESE_CODE,
  CHINESE_FLAG,
  ENGLISH,
  ENGLISH_CODE,
  ENGLISH_FLAG,
  MEXICAN,
  MEXICAN_CODE,
  MEXICAN_FLAG,
} from '~/assets/strings';
import { useLocalization } from '~/hooks/useLocalization';
import iconZh from '../icons/chinese.png';
import iconEn from '../icons/english.png';
import iconMx from '../icons/mexican.png';

type Option = {
  id: string;
  value: string;
  label: string;
  img?: string;
  alt?: string;
};

export const LocalizationButton = () => {
  const [isOpen, setIsOpen] = createSignal<boolean>(false);
  const [selectedOption, setSelectedOption] = createSignal<Option>();

  const localization = useLocalization();

  const languageOptions: Option[] = [
    { id: ENGLISH_CODE, value: ENGLISH_CODE, label: ENGLISH, img: iconEn, alt: ENGLISH_FLAG },
    { id: MEXICAN_CODE, value: MEXICAN_CODE, label: MEXICAN, img: iconMx, alt: MEXICAN_FLAG },
    { id: CHINESE_CODE, value: CHINESE_CODE, label: CHINESE, img: iconZh, alt: CHINESE_FLAG },
  ];

  onMount(() => {
    setSelectedOption(languageOptions.find((option) => option.value === localization.currentLanguage()) || languageOptions[0]);
  });

  const handleOptionClicked = (option: Option) => {
    setSelectedOption(option);
    localization.changeLanguage(option.value);
    setIsOpen(false);
  };

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div class="text-sm text-text-level01 md:relative">
      <button
        onClick={handleToggle}
        classList={{ 'bg-primary-color/10 md:bg-primary-color/20': isOpen() }}
        class="flex items-center gap-1 rounded-full border px-3 py-2.5 font-medium uppercase text-secondary-color outline-0 transition focus-within:ring md:border-0 md:bg-gray-50 md:px-4 md:py-3 md:font-semibold">
        <Show when={selectedOption()?.img}>
          <img class="h-4" src={selectedOption()?.img} alt={selectedOption()?.alt || ''} />
        </Show>
        {selectedOption()?.value}
      </button>
      <Show when={isOpen()}>
        <div class="fixed inset-x-0 bottom-0 z-50 bg-black/50 md:hidden" onClick={handleToggle} />
        <ul class="absolute inset-x-0 top-full z-1 divide-y divide-slate-100 border border-slate-200 bg-white px-4 py-2 shadow-lg md:left-auto md:right-0 md:mt-2 md:w-48 md:rounded-md md:px-1">
          <For each={languageOptions}>
            {(option) => (
              <li
                class="hover:bg-primary-color/10 flex cursor-pointer items-center gap-2 px-1 py-4 transition md:rounded-md md:p-3"
                onClick={() => handleOptionClicked(option)}>
                <Show when={option.img}>
                  <img src={option.img} alt={option.alt || ''} />
                </Show>
                {option.label}
                <Show when={selectedOption()?.id === option.id}>
                  <svg
                    class="ml-auto text-primary-color"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.166 14.7L18.4251 5.44141L19.9676 6.9845L9.16818 17.784L7.678 16.2932L7.67527 16.296L3.81836 12.4385L5.3609 10.8954L9.166 14.7Z" />
                  </svg>
                </Show>
              </li>
            )}
          </For>
        </ul>
      </Show>
    </div>
  );
};
